<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import * as THREE from "three";
// import * as TWEEN from "tweenjs";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js";
// import { MTLLoader } from "three-obj-mtl-loader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
// import { SkeletonUtils } from "three/examples/jsm/utils/three/examples/jsm/controls/SkeletonUtils";
let colorIndex = 0; // 高亮颜色流动的索引值
let timestamp = 0; // 时间戳
// import { TextureLoader } from "three/examples/jsm/loaders/TextureLoader";
// var zmesh;
// var FLOOR = -250;
export default {
  name: "ThreeTest",
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      controls: "",
      intersections: null,
      objects: [],
      clock: "",
      moveForward: false,
      moveLeft: false,
      moveBackward: false,
      moveRighta: false,
      direction: new THREE.Vector3(),
      velocity: new THREE.Vector3(),
      prevTime: performance.now(),
      femaleobj: null,
      materialsobj: null,
      geometry11:null,
      divisions:null
    };
  },
  methods: {
    init: function () {
      this.initScene(); //场景对象
      this.initCamera(); //相机
      this.initWebGLRenderer(); //渲染器
      this.initAxisHelper(); //辅助坐标
      // this.render();
      this.createControls(); //控件对象
      this.Box();
      this.initControls(); //相机视角
      this.initPlane(); //地板
      this.initMobile(); //移动
      this.threeobj(); //引入3d模型
      this.render();
      //   let turnAnimation = new TWEEN.Tween(model.rotation)
      //     .to(
      //       {
      //         //动画过渡 y 1.8
      //         y: rotation,
      //       },
      //       time
      //     )
      //     .onStart(function () {})
      //     .onUpdate(function (data) {})
      //     .onComplete(function () {});
      // 	turnAnimation.start();
    },
    //  handleClick(pixel){
    //       let intersects = this.getObjectsAtPixel(pixel);
    //       if(intersects.length != 0){
    //         let destination= null;
    //         for(let i=0;i<intersects.length;i++){
    //           let currPoint = intersects[i].point,
    //             object = intersects[i].object;
    //             if(object.isFloor){
    //               destination = currPoint;
    //               break;
    //             }
    //         }
    //     }
    // 	},

    // createScene(geometry, materials, x, y, z) {
    // 	const that = this;
    //     zmesh = new THREE.Mesh(geometry, materials);
    //     zmesh.position.set(x, y, z);
    //     zmesh.scale.set(3, 3, 3);
    //     that.scene.add(zmesh);

    //     // createMaterialsPalette( materials, 100, b );
    //   },
    //引入3d模型
    threeobj() {
      const that = this;

      //   var loader = new THREE.JSONLoader();
      //   //   var callbackMale = function (geometry, materials) {
      //   //     createScene(geometry, materials, 90, FLOOR, 50, 105);
      //   //   };
      //   var callbackFemale = function (geometry, materials) {
      //     that.createScene(geometry, materials, -80, FLOOR, 50);
      //   };

      //   //   loader.load("models/json/male02/Male02_dds.json", callbackMale);
      //   loader.load("female02/Female02_slim.json", callbackFemale);

      // 实例化obj loader
      this.objLoader = new OBJLoader();
      // 实例化mtl loader
      this.mtlLoader = new MTLLoader();

      // 加载mtl

      //   var mapWood = new TextureLoader().load(
      //     "female02/female02_vertex_colors.JPG"
      //   );
      //   that.materialsobj = new THREE.MeshStandardMaterial({
      //     map: mapWood,
      //     side: THREE.DoubleSide,
      //   });

      this.mtlLoader.load("female02/female02.mtl", function (materials) {
        // materials.preload();
        that.objLoader.setMaterials(materials);
        // 加载obj
        that.objLoader.load("female02/female02.obj", function (obj) {
          // 模型文件太大，缩小一下比例，方便显示
          obj.scale.set(1, 1, 1);
          // 设置可以投影
          obj.children.forEach((item) => {
            item.castShadow = true;
            item.receiveShadow = true;
          });
          that.femaleobj = obj;
          console.log(obj);
          // 添加到场景
          // that.scene.add(that.femaleobj);
        });
      });

      // that.femaleobj = SkeletonUtils.clone( that.scene );
    },
    //鼠标控制移动相机视角*****
    initControls() {
      let that = this;
      that.controls = new PointerLockControls(this.camera, document.body);
      //   var container = document.getElementById("container");
      //   container.addEventListener("click", function () {
      //     that.controls.lock();
      //   });
      this.scene.add(that.controls.getObject());
    },
    initMobile() {
      let that = this;
      console.log(this.controls);
      var onKeyDown = function (event) {
        switch (event.keyCode) {
          case 38: // up
          case 87: // w
            that.moveForward = true;
            break;

          case 37: // left
          case 65: // a
            that.moveLeft = true;
            break;

          case 40: // down
          case 83: // s
            that.moveBackward = true;
            break;

          case 39: // right
          case 68: // d
            that.moveRighta = true;
            break;
        }
      };

      var onKeyUp = function (event) {
        switch (event.keyCode) {
          case 38: // up
          case 87: // w
            that.moveForward = false;
            break;

          case 37: // left
          case 65: // a
            that.moveLeft = false;
            break;

          case 40: // down
          case 83: // s
            that.moveBackward = false;
            break;

          case 39: // right
          case 68: // d
            that.moveRighta = false;
            break;
        }
      };

      document.addEventListener("keydown", onKeyDown, false);
      document.addEventListener("keyup", onKeyUp, false);
    },
    //   创建场景对象Scene
    initScene() {
      this.scene = new THREE.Scene();
    },
    //   相机
    initCamera() {
      let container = document.getElementById("container");
      this.camera = new THREE.PerspectiveCamera(
        60,
        container.clientWidth / container.clientHeight,
        1,
        1000
      );
      this.camera.position.set(292, 109, 268); //设置相机位置
      this.camera.lookAt(this.scene.position); //设置相机方向(指向的场景对象)
    },
    Box() {
      //正方形
      let geometry = new THREE.BoxGeometry(50, 50, 50);
      let material = new THREE.MeshNormalMaterial();
      this.mesh = new THREE.Mesh(geometry, material);
      // this.scene.add(this.mesh);

      const point1 = [50, 0, 0]; // 点1坐标
      const point2 = [-50, 0, 0]; // 点2坐标
      const controlPoint = [0, 50, 0]; // 控制点坐标

      // 创建三维二次贝塞尔曲线
      const curve = new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(point1[0], point1[1], point1[2]),
        new THREE.Vector3(controlPoint[0], controlPoint[1], controlPoint[2]),
        new THREE.Vector3(point2[0], point2[1], point2[2])
      );
      this.divisions = 30; // 曲线的分段数量
      const points = curve.getPoints(this.divisions); // 返回 分段数量 + 1 个点，例如这里的points.length就为31
      // 创建Geometry
      this.geometry11 = new THREE.BufferGeometry();
      this.geometry11.vertices = points; // 将上一步得到的点列表赋值给geometry的vertices属性
      // 设置顶点 colors 数组，与顶点数量和顺序保持一致。
      this.geometry11.colors = new Array(points.length).fill(
        new THREE.Color("#333300")
      );
      // 生成材质
      const material11 = new THREE.LineBasicMaterial({
        vertexColors: THREE.VertexColors, // 顶点着色
        transparent: true, // 定义此材质是否透明
        side: THREE.DoubleSide,
      });
      const mesh11 = new THREE.Line(this.geometry11, material11);
      this.scene.add(mesh11);
    },
    //地板
    initPlane() {
      var planeGeometry = new THREE.PlaneGeometry(600, 600);
      //平面使用颜色为0xcccccc的基本材质
      var planeMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      var plane = new THREE.Mesh(planeGeometry, planeMaterial);
      //设置屏幕的位置和旋转角度
      plane.rotation.x = -0.5 * Math.PI;
      plane.position.x = 0;
      plane.position.y = 0;
      plane.position.z = 0;
      //将平面添加场景中
      //   this.scene.add(plane);
    },
    //创建渲染器对象
    initWebGLRenderer() {
      this.container = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      // this.renderer.autoClear = false;
      this.renderer.setClearColor(0xb9d3ff, 1); //设置背景颜色
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      ); //设置渲染区域尺寸
      this.container.appendChild(this.renderer.domElement); //body元素中插入canvas对象
    },
    render() {
      // this.controls.update();
      // 时间间隔
      let now = new Date().getTime();
      if (now - timestamp > 30) {
        this.geometry11.colors = new Array(this.divisions + 1)
          .fill(new THREE.Color("#333300"))
          .map((color, index) => {
            if (index === colorIndex) {
              return new THREE.Color("#ffff00");
            }
            return color;
          });
        // 如果geometry.colors数据发生变化，colorsNeedUpdate值需要被设置为true
       this.geometry11.colorsNeedUpdate = true;
        timestamp = now;
        colorIndex++;
        if (colorIndex > this.divisions) {
          colorIndex = 0;
        }
      }
      // this.controls.update();
      this.renderer.render(this.scene, this.camera); //执行渲染操作
      requestAnimationFrame(this.render); //请求再次执行渲染函数render
    },
    //辅助三维坐标系AxisHelper
    initAxisHelper() {
      this.axesHelper = new THREE.AxesHelper(250);
      this.scene.add(this.axesHelper);
    },
    // 创建控件对象
    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // 启用阻尼（惯性），这将给控制器带来重量感，如果该值被启用，必须在动画循环里调用.update()
      this.controls.dampingFactor = 0.05; // 阻尼惯性大小
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
#container {
  height: 400px;
}
</style>
